export const paths = {
  auth: {
    login: '/',
    loginUser: '/login',
    addNewPassword: '/add-new-password',
    forgotPassword: '/forget-password',
    otp: '/otp',
    changePassword: '/change-password',
  },
  owner: {
    multiVenueDashboard: '/owner/multi-venue-dashboard',
    dashboard: '/owner/dashboard',
    inventory: '/owner/inventory',
    items: '/owner/items',
    recipes: '/owner/recipes',
    addRecipe: '/owner/add-recipe',
    editRecipe: '/owner/edit-recipe',
    orders: '/owner/orders',
    reports: '/owner/reports',
    history: '/owner/history',
    setting: '/owner/settings',
    cart: '/owner/cart',
    suppliers: '/owner/suppliers',
    stocks: '/owner/stocks',
    sales: '/owner/sales',
    variances: '/owner/variances',
    purchase: '/owner/purchase',
    costChanges: '/owner/cost-changes',
    historyInventory: '/owner/history-inventory',
    historyOrders: '/owner/history-orders',
    orderReceipt: '/owner/order-receipt',
    orderDetail: '/owner/order-detail',
    account: '/owner/account',
    resetPassword: '/owner/reset-password',
    mySubscription: '/owner/my-subscription',
    barManager: '/owner/bar-manager',
    staffMember: '/owner/staff-member',
    apps: '/owner/apps',
  },
  manager: {
    multiVenueDashboard: '/manager/multi-venue-dashboard',
    dashboard: '/manager/dashboard',
    inventory: '/manager/inventory',
    items: '/manager/items',
    recipes: '/manager/recipes',
    addRecipe: '/manager/add-recipe',
    editRecipe: '/manager/edit-recipe',
    orders: '/manager/orders',
    reports: '/manager/reports',
    history: '/manager/history',
    setting: '/manager/settings',
    cart: '/manager/cart',
    suppliers: '/manager/suppliers',
    stocks: '/manager/stocks',
    sales: '/manager/sales',
    variances: '/manager/variances',
    purchase: '/manager/purchase',
    costChanges: '/manager/cost-changes',
    historyInventory: '/manager/history-inventory',
    historyOrders: '/manager/history-orders',
    orderReceipt: '/manager/order-receipt',
    orderDetail: '/manager/order-detail',
    account: '/manager/account',
    resetPassword: '/manager/reset-password',
    mySubscription: '/manager/my-subscription',
    barManager: '/manager/bar-manager',
    staffMember: '/manager/staff-member',
    apps: '/manager/apps',
  },
  admin: {
    dashboard: '/admin/dashboard',
    barowner: '/admin/bar-owner-management',
    barOwnerVenue: '/admin/bar-owner-venue-management',
    subscription: '/admin/subscription-management',
    inquiry: '/admin/inquiry-management',
    reports: '/admin/reports',
    category: '/admin/category',
    subCategory: '/admin/subcatgeory',
    caseSize: '/admin/case-size',
    unitMeasure: '/admin/unit-measure',
    container: '/admin/container',
    breakageAndLoss: '/admin/breakage-loss',
    item: '/admin/items',
    ingredient: '/admin/ingredient',
    generalSettings: '/admin/general-settings',
    mailSettings: '/admin/mailSettings',
    auditLogs: '/admin/audit-logs',
    account: '/admin/account',
    resetPassword: '/admin/reset-password',
    contactInquiry: '/admin/contact-inquiry',
    subscriptionInquiry: '/admin/subscription-inquiry',
  },
}
