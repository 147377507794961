import axios from 'axios'
import { avgDeliveryDaysOptions } from '../constants/commonConstants'
import { ToastShow } from '../redux/slices/toastSlice'
import { store } from '../redux/store'
import { axiosGet } from '../axios/axiosMiddleware'

export const checkValueInt = (value) => {
  let flag = false
  if (!isNaN(parseInt(value)) || value?.toString() === '') {
    flag = true
  }
  return flag
}

export const deleteToastFun = (message, type) => {
  store.dispatch(ToastShow({ message: message, type: type }))
}

export const capitalizeFunction = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const isValidArray = (data) => data && Array.isArray(data) && data.length > 0

export function getLabelByValue(value) {
  const option = avgDeliveryDaysOptions.find((option) => option.value === value)
  return option ? option.label : null
}

export const CalculateTotalPrice = (qty, unitsofmeasure, totalPricePerLiter) => {
  let qtyInLiters = 0
  console.log(qty, unitsofmeasure, totalPricePerLiter, 'qty, unitsofmeasure, totalPricePerLiter')
  if (totalPricePerLiter !== 'Nan' || totalPricePerLiter !== 'undefined' || totalPricePerLiter !== null) {
    switch (unitsofmeasure?.toLowerCase()) {
      case 'ml':
        qtyInLiters = qty / 1000 // Convert milliliters to liters
        break
      case 'kg':
        qtyInLiters = qty
        break
      case 'litre':
        qtyInLiters = qty // Already in liters
        break
      case 'liter':
        qtyInLiters = qty // Already in liters or kg
        break
      default:
        break
    }
  } else {
    qtyInLiters = 0
  }

  // considered the 1 ml 2 litre or 3 kg.
  const costPrice = qtyInLiters * totalPricePerLiter
  return costPrice.toFixed(2)
}

export const calculateGrandTotal = (items) => {
  const total = items.reduce((total, item) => {
    return total + parseFloat(item.totalCostPrice)
  }, 0)

  return total.toFixed(2)
}

export const handleAlertUpdateIconType = (update_type) => {
  let iconType = ''
  switch (update_type) {
    case 'FULL INVENTORY':
      iconType = 'warning'
      break
    case 'QUICK INVENTORY':
      iconType = 'warning'
      break
    case 'BREAKAGE & LOSS':
      iconType = 'warning'
      break
    case 'TRANSFER':
      iconType = 'primary'
      break
    case 'ORDER':
      iconType = 'primary'
      break
    case 'VERIANCE':
      iconType = 'success'
      break
    case 'COST_CHANGE':
      iconType = 'warning'
      break
    default:
      break
  }
  return iconType
}
export const NotificationIconType = (update_type) => {
  let iconType = ''
  switch (update_type) {
    case 'WARNING':
      iconType = 'warning'
      break
    case 'ERROR':
      iconType = 'danger'
      break
    case 'MESSAGE':
      iconType = 'sucess'
      break
    default:
      iconType = 'info'
      break
  }
  return iconType
}

export const fetchImageAsBase64 = async (url) => {
  try {
    const response = await fetch(url)
    const blob = await response.blob()

    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    })
  } catch (error) {
    console.error('Error fetching image:', error)
    return null // or a default fallback image
  }
}

export const checkValidImageSize = (value, size) => {
  if (value.size <= size * 1000000) return true
  else return false
}
export const imageSize = 30
export const videoExtension = ['.mp4', '.mov', '.wvm', '.mkv', '.webm', '.avi', '.flv', 'avchd']
export const imageExtension = ['.jpg', '.jpeg', '.jfif', '.pjpeg', '.pjp', '.png', '.svg', '.webp']
export const otherSize = 5
export const videoSize = 500
