import * as Yup from 'yup'

export const addNewRefernceValidationSchema = () =>
  Yup.object().shape({
    item_name: Yup.string()
      .trim()
      .max(50, 'Maximum 50 Characters allowed')
      .min(1, 'Minimum 1 Characters required')
      .required('Item name is required'),
    brand_name: Yup.string()
      .trim()
      .max(50, 'Maximum 50 Characters allowed')
      .min(1, 'Minimum 1 Characters required')
      .required('Item brand is required'),
    category: Yup.string().required('Category name is required'),
    sub_category: Yup.string().required('Subcategory name is required'),
    barcode: Yup.string().trim().required('Barcode is required'),
    vendor: Yup.string().required('Supplier name is required'),
    unit_size: Yup.number().typeError('Unit size must be a number').required('Unit size is required').min(1),
    unit_of_measure: Yup.string().required('Unit of measure is required'),
    container: Yup.string().required('Container is required'),
    cost_price: Yup.number().typeError('Cost price must be a number').min(1).required('Cost price is required'),
    alc_per_volume: Yup.number().typeError('Alcohol per volume must be a number'),
    PLU_code: Yup.string().trim().required('PLU code is required'),
    sell_price: Yup.number().typeError('Sell price must be a number').required('Sell price is required'),
    tare_or_weight: Yup.number().required('Full item weight is required'),
    tare_or_weight_empty: Yup.number().required('Empty item weight required'),
  })

export const addNewRefernceValidationSchemawithIndividual = () =>
  Yup.object().shape({
    item_name: Yup.string()
      .trim()
      .max(50, 'Maximum 50 Characters allowed')
      .min(1, 'Minimum 1 Characters required')
      .required('Item name is required'),
    brand_name: Yup.string()
      .trim()
      .max(50, 'Maximum 50 Characters allowed')
      .min(1, 'Minimum 1 Characters required')
      .required('Item brand is required'),
    category: Yup.string().required('Category name is required'),
    sub_category: Yup.string().required('Subcategory name is required'),
    barcode: Yup.string().trim().required('Barcode is required'),
    vendor: Yup.string().required('Supplier name is required'),
    unit_size: Yup.number().typeError('Unit size must be a number').required('Unit size is required').min(1),
    unit_of_measure: Yup.string().required('Unit of measure is required'),
    container: Yup.string().required('Container is required'),
    cost_price: Yup.number().typeError('Cost price must be a number').min(1).required('Cost price is required'),
    alc_per_volume: Yup.number()
      .typeError('Alcohol per volume must be a number')
      .required('Alcohol per volume is required'),
    PLU_code: Yup.string().trim().required('PLU code is required'),
    sell_price: Yup.number().typeError('Sell price must be a number').required('Sell price is required'),
    tare_or_weight: Yup.number().required('Full item weight is required'),
    tare_or_weight_empty: Yup.number().required('Empty item weight required'),
  })

// bar_venue
// item_name
// brand_name
// category
// sub_category
// barcode
// vendor
// unit_size
// unit_of_measure
// container
// case_size
// cost_price
// is_sold_individually
// alc_per_volume
// PLU_code
// sell_price
// tare_or_weight_checked
// tare_or_weight
// tare_or_weight_unit_of_measure
// tare_or_weight_empty_checked
// tare_or_weight_empty
// tare_or_weight_unit_of_measure_empty
