/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Paragraph from '../../components/core/typography/Paragraph'
import WhiteCard from '../../components/themeComponents/WhiteCard'
import ListItem from '../../components/themeComponents/ListItem'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import Button from '../../components/core/formComponents/Button'
import { activeVenueSelector } from '../../redux/slices/ownerVenueSlice'
import { useSelector } from 'react-redux'
import { GetCartOrderSummary, GetCartOrderSupplier, SendSingleOrder } from '../../services/cartService'
import noImage from '../../assets/images/noImg.png'
import Modal from '../../components/core/Modal'
import SendAllOrders from './SendAllOrders'

function ReviewCart({ setShowButton }) {
  const activeVenue = useSelector(activeVenueSelector)
  const [orderSummaryData, setOrderSummaryData] = useState({})
  const [supplierData, setSupplierData] = useState([])
  useEffect(() => {
    if (activeVenue > 0) {
      getOrderSummary()
      getSummarySupplierData()
    }
  }, [activeVenue])

  const getOrderSummary = async () => {
    if (activeVenue > 0) {
      const response = await GetCartOrderSummary(activeVenue)
      if (response?.status === 200) {
        setOrderSummaryData?.(response?.data?.data)
      }
    }
  }

  const getSummarySupplierData = async () => {
    if (activeVenue > 0) {
      const response = await GetCartOrderSupplier(activeVenue)
      if (response?.status === 200) {
        setSupplierData(response?.data?.data)
        if (response?.data?.data?.length > 0) {
          setShowButton(true)
        } else {
          setShowButton(false)
        }
      }
    }
  }

  const handleSubmitSendOrder = async (supplier) => {
    if (activeVenue && supplier) {
      const data = {
        bar_venue: activeVenue,
        item: supplier?.item[0]?.id,
        supplier: supplier?.id,
        total_qty: supplier?.total_qty_all_item,
        total_amount: supplier?.total_price_all_item,
      }
      const response = await SendSingleOrder(data)
      if (response?.status === 200) {
        getOrderSummary()
        getSummarySupplierData()
      }
    }
  }

  return (
    <div className='grid grid-cols-12 lg:gap-6 sm:gap-4 gap-3 w-full mt-6'>
      <div className='col-span-12'>
        <Paragraph text20>Order Summary</Paragraph>
      </div>
      <div className='xxl:col-span-8 xl:col-span-7 md:col-span-6 col-span-12'>
        <WhiteCard p20 className={'w-full sticky top-[96px]'}>
          {orderSummaryData?.items?.map((itemdata, index) => {
            const isLastItem = index === orderSummaryData?.items?.length - 1
            return (
              <ListItem
                key={index}
                {...(!isLastItem && { borderBottom: true })}
                defaultItem
                withCount
                className='mb-2 !px-0 !items-center'
                itemName={itemdata?.item?.item_name}
                productImage={itemdata?.item?.item_image ?? noImage}
                currency={'AED'}
                price={itemdata?.total_cost}
                qty={itemdata?.qty_of_unit}
                imgSize={'42px'}
                fontSize={'md:text-lg sm:text-base text-sm'}
              />
            )
          })}
          <ul className='bg-light-grey p-4 rounded-2xl'>
            <li className='flex items-center justify-between gap-3 mb-5'>
              <Paragraph text18 className={'font-bold'}>
                Quantity
              </Paragraph>
              <Paragraph text18 className={'font-bold'}>
                {orderSummaryData?.total_qty ?? 0}
              </Paragraph>
            </li>
            <li className='flex items-center justify-between gap-3 mb-5'>
              <Paragraph text18 className={'font-bold'}>
                Total
              </Paragraph>
              <Paragraph text18 className={'font-bold'}>
                AED {orderSummaryData?.total_amount ?? 0}
              </Paragraph>
            </li>
            <li className='flex items-center justify-between gap-3'>
              <Paragraph text18 className={'font-bold'}>
                Supplier
              </Paragraph>
              <Paragraph text18 className={'font-bold'}>
                {orderSummaryData?.total_suppliers ?? 0}
              </Paragraph>
            </li>
          </ul>
        </WhiteCard>
      </div>

      <div className='xxl:col-span-4 xl:col-span-5 md:col-span-6 col-span-12'>
        {console.log(supplierData, 'supplierData')}
        {supplierData?.length > 0 &&
          supplierData?.map((supplier) => (
            <WhiteCard p20 className={'lg:mb-6 sm:mb-4 mb-3'}>
              <Paragraph text20>{supplier?.supplier_name}</Paragraph>
              <ul className='flex flex-wrap items-center justify-start gap-[7px] mt-4'>
                <li className='flex items-center justify-start gap-2 bg-light-grey rounded-lg py-1 px-3'>
                  <Paragraph text14 className={'text-dark-grey !font-normal'}>
                    Qty
                  </Paragraph>
                  <Paragraph text14 className={'font-semibold'}>
                    {supplier?.total_qty_all_item ?? 0}
                  </Paragraph>
                </li>
                <li className='flex items-center justify-start gap-2 bg-light-grey rounded-lg py-1 px-3'>
                  <Paragraph text14 className={'text-dark-grey !font-normal'}>
                    Min. Order
                  </Paragraph>
                  <Paragraph text14 className={''}>
                    {supplier?.min_order + 'AED' ?? 0}
                  </Paragraph>
                </li>
                <li className='flex items-center justify-start gap-2 bg-light-grey rounded-lg py-1 px-3'>
                  <Paragraph text14 className={'text-dark-grey !font-normal'}>
                    Total
                  </Paragraph>
                  <Paragraph text14 className={''}>
                    {supplier?.total_price_all_item + 'AED' ?? 0}
                  </Paragraph>
                </li>
              </ul>
              <div className='border-medium-grey border rounded-lg p-3 mt-3'>
                <div className='bg-light-grey rounded-[6px] px-3 py-[7px]'>
                  <Paragraph text14 className={'text-dark-grey !font-bold'}>
                    SUBJECT
                  </Paragraph>
                  <Paragraph text14>{`Order for I'atelier Robuchon-Date:12/08/24`}</Paragraph>
                </div>
                <ReactQuill
                  theme='snow'
                  value={supplier?.email_template?.content}
                  readOnly
                  // onChange={setValue}
                  placeholder='I hope this email finds you well. i am writing to place an order for the following items:'
                  className='px-0 mt-2'
                />
              </div>
              {supplier?.total_price_all_item >= supplier?.min_order && (
                <Button primary className={'w-full mt-5'} onClick={() => handleSubmitSendOrder(supplier)}>
                  Send order
                </Button>
              )}
            </WhiteCard>
          ))}
      </div>
    </div>
  )
}

export default ReviewCart
